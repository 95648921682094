@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;1,200;1,300;1,400;1,600&display=swap');



.btn-wpp {
    background-color: #1BD741;


    width: 283.5px;
    height: 42px;

    border-radius: 20px;

    border: none;
    color: black;
    text-decoration: none;
    font-size: 15px;
    cursor: pointer;
    font-family: 'Titillium Web', Arial, Helvetica, sans-serif;
    letter-spacing: 0.05em; 
    font-weight: 600;
    text-align: center;
    filter: drop-shadow(0px 4px 4px rgba(27, 215, 65, 1));

    margin-top: -5vh;
    margin-bottom: 10vh;
    transition: color .3s ease;
}
.btn-wpp:hover{
   /* background-color: #000;*/
    color: #Fff;
}
button .iconwpp {
    margin-right: 4%;
    margin-top: 0;
    margin-bottom: -1%;
    margin-left: 0;

    width: 18px;
    height: 18px;


    position: relative;
}