.div-out {
    overflow: hidden;
    width: 100%;
    height: 100vh;
    background-color: black;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1999;
    top: 0;
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
    
}

.it {
    position: fixed;
    top: 10px;
    right: 10px;
    height: 3rem;
    width: 3rem;
    padding: 5px;
    background-color: rgba(0,0,0, 0.4);
    color:  #fff;
    cursor: pointer;
}

.itnext {
    position: fixed;
    bottom: 50%;
    right: 10%;
    height: 3rem;
    width: 3rem;
    padding: 5px;
    background-color: rgba(0,0,0, 0.4);
    color:  #fff;
    cursor: pointer;
}

.itprevious {
    position: fixed;
    bottom: 50%;
    left: 10%;
    height: 3rem;
    width: 3rem;
    padding: 5px;
    background-color: rgba(0,0,0, 0.4);
    color: blue;
    cursor: pointer;  
}

.img-out {
    width: auto;
    max-width: 90%;
    max-height: 90%;
}
/*----------------*/
.gallery-global {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
  }
  .inside-gallery{
      display: flex;
      width: 70%;
      height: 100%;
      align-items: center;
  }
  .responsive-masonry{
    display: flex;
    width: 100%;
    height: 420px;
    align-items: center;  
}
.masonry-columnn {
    height: 100%;
    width: 100%;
}
.img-gallery-container {
    display: inline-block;

    
    width: 550px;
    height: 100%;
    cursor: pointer;  
    margin-left: 2vw;

    overflow: hidden;

    position: relative;
  }
  .img-gallery-container img {
    display: block;
    width: 100%;
    height: auto;
    transition: transform 0.3s ease-in-out;
  }
  .img-gallery-container:hover img {
    transform: scale(1.05); /* Efeito de leve zoom ao passar o mouse */
  }
  .goverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7); /* Fundo branco semi-transparente */
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  .img-gallery-container:hover .goverlay {
    opacity: 1;
  }
  .goverlay-text {
    color: #000; /* Cor do texto */
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    padding: 10px;
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;

  }
  .immg-responsive {
    width: 100%; /* Garante que a imagem ocupe toda a largura do container */
    cursor: pointer;
    transition: filter 0.3s ease;
}

.immg-responsive:hover {
    filter: brightness(1.35);
}



@media (max-width: 991px){
    .gallery-global{
        width: 400px;
        padding-left: 5%;      
    }
}
@media screen and (max-width: 768px) {
    .inside-gallery {
        width: 90%; /* Aumenta a largura da galeria para telas menores */
    }

    .responsive-masonry {
        height: auto; /* Permite que o Masonry ajuste sua altura dinamicamente */
        display: flex;
        flex-direction: column; /* Organiza os itens em coluna */
    }

    .img-gallery-container {
        width: 100%; /* Faz com que as imagens ocupem toda a largura disponível */
        height: auto;
        margin-left: 0; /* Remove margem lateral */
        margin-bottom: 15px; /* Espaço entre imagens */
    }

    .goverlay-text {
        font-size: 14px; /* Reduz o tamanho da fonte para caber melhor */
        padding: 5px;
    }
}

@media screen and (max-width: 480px) {
    .inside-gallery {
        width: 100%; /* Usa a largura total */
    }

    .img-gallery-container {
        width: 100%; /* Ocupa toda a tela no mobile */
        height: auto;
    }

    .goverlay {
        background: rgba(255, 255, 255, 0.85); /* Aumenta um pouco a opacidade para melhor leitura */
    }

    .goverlay-text {
        font-size: 12px; /* Ajusta a fonte para telas bem pequenas */
    }
}


/* model*/

.modelLeft {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 999;
}


.modelCenter {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 999;
}



.model.open{
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.model img {
    width: auto;
    max-width: 100%;
    max-height: 100%;
    height: auto;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
}

.model.open svg{
    position: fixed;
    top: 10px;
    right: 10px;
    height: 3rem;
    width: 3rem;
    padding: 5px;
    background-color: rgba(0,0,0, 0.4);
    color: #fff;
    cursor: pointer;
}
/*-----*/
.img-container {
    width: 100%;
    position: inline-block;
    display: flex; /* Permite ajustar ao tamanho do conteúdo */
    cursor: pointer;  
  }
.img-container .overlay {
    position: absolute;
    top: 0;
    left: 0;
    /*width: 160%;*/
    height: 100%;
    background: rgba(255, 255, 255, 0.6); /* Filtro branco com baixa opacidade */
    opacity: 0;
    transition: opacity 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
 
  }
  .img-container:hover .overlay {
    opacity: 1;
  }