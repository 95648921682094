.globaltopbar {
    width: 100%;
    height: 4vh;
    display: flex;

    background-color: #F58320;
}

.blackline{
    width: 100%;
    height: 0.5vh;
    display: flex;

    background-color: black;
}

.logotopbar {
    width: 5%;
    margin: auto;
  display: block;
}

@media (max-width: 480px){
    .logotopbar {
        width: 20%;
    }
  }