@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;1,200;1,300;1,400;1,600&display=swap');



form {
    width: auto;    
    height: 350px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

fieldset {
    border: none;
    width: 315px;
    margin: 10vh 0;
}

.input-vertical-block {
    display: flex;
    flex-direction: column;
    padding: 5%;
}

.input-vertical-block input, button {
    width: 100%;
    height: 42px;
    border-radius: 20px;
    margin-top: 3vh;
    border: 1px solid #F58320;
    font-size: 15px;
    cursor: pointer;
    font-family: 'Nunito', Arial, Helvetica, sans-serif;
    letter-spacing: 0.05em; 
    font-weight: 4  00;
    text-align: center;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, .3));
}

.input-vertical-block input::placeholder {
    color: #7C7777;
}

.input-vertical-block button {
    background-color: #F58320;
    color: #fff;
    
}
