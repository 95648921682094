@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;1,200;1,300;1,400;1,600&display=swap');



.btn-default {
    background: #F58320;
border-radius: 6px;


    width: 250px;
    height: 42px;

    border: none;
    color: #fff;
    text-align: center;
    text-decoration: none;
    font-size: 13px;
    cursor: pointer;
    font-family: 'Nunito', Arial, Helvetica, sans-serif;
    letter-spacing: 0.05em; 
    font-weight: 500;

   
}