.dbcontent {
    display: flex;
    width: 100%;
    background-color: yellow;
  }
  
  .first-part,
  .second-part {
    width: 50%;
    position: relative;
  }
  
  .first-part {
    width: 66.66%;
    background-color: red;
    padding-left: 20px;
    padding-top: 80px;
  }
  
  .second-part {
    width: 33.33%;
    background-color: pink;
  }
  
  .image-container {
    position: relative;   
  }
  
  .image-container img {
    width: 150px;
    height: auto;
    transform: rotate(-5deg);
  }
  
  .text-container,
  .button-container {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .text-container p {
    /* Estilize o texto conforme necessário */
  }
  

.portfolio {
    width: 100%;
    height: 100%;
}
.unicrouteport, .unicroutecomp, .unicroutecli, .unicroutecont{
  margin-top: 14vh;
}
.double{
    width: 100%;
    display: flex;
    height: 100vh;
    margin-top: 4vh;
    
}
.home {
    width: 100%;
    display: flex;
    height: 100%;
}

.divteste{
  display: flex;
  width: 100%;
  height: 450px;
  align-items: center;
  justify-content: center;
  margin-top: 5vh;
}


.overlay {
    position: relative;
    width: 100%;
    height: 100%;
    padding-left: 3%;
    padding-top: 5%;
    padding-right: 5%;
  }

  .overlay-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Cor preta com opacidade baixa */
  }

  .top-left-text {
    position: absolute;
    left: 25px;
    top: 10vh;
  }

  .top-left-text img {
    width: 150px;
    height: auto;
    transform: rotate(-5deg);
  }

  .overlay-content {
    position: absolute;
    /*top: 0;  Alterado para 0 para garantir que todos os elementos estejam alinhados corretamente */
    left: 0; /* Alterado para 0 para garantir que todos os elementos estejam alinhados corretamente */
    width: 100%;
    height: 100%;
    text-align: center;
    color: white;
    z-index: 0; /* Alterado para 0 para garantir que esteja atrás da camada de sobreposição */
  }

  .selo {
    position: relative;
  width: 150px; /* Tamanho do selo */
  height: 150px; /* Tamanho do selo */
  border-radius: 50%; /* Tornar o selo circular */
  background-color: #d3a247; /* Cor de fundo do selo */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra do selo */
  }
  .selo::before,
.selo::after {
  content: '';
  position: absolute;
  width: 20px; /* Largura da "ponta" do selo */
  height: 20px; /* Altura da "ponta" do selo */
  background-color: #d3a247; /* Cor da "ponta" do selo */
}
.selo::before {
    top: -10px; /* Posição vertical da "ponta" superior */
    left: 50%; /* Posição horizontal da "ponta" superior */
    transform: translateX(-50%) rotate(45deg); /* Inclinação da "ponta" superior */
  }
  .selo::after {
    bottom: -10px; /* Posição vertical da "ponta" inferior */
    left: 50%; /* Posição horizontal da "ponta" inferior */
    transform: translateX(-50%) rotate(45deg); /* Inclinação da "ponta" inferior */
  }
  .center-right {
    position: absolute;
    top: 50%;
    right: 3%;
    transform: translateY(-50%);
    text-align: right;
    color: white;
  }
  
  .center-right h2.text-title-section {
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    text-align: right;
    letter-spacing: 0.015em;
    color: #fff;
  }

.carousel {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
  }
  
  .slide {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    position: absolute;
    transition: opacity 0.5s ease;
    opacity: 0;
  }
  
  .slide.active {
    opacity: 1;
  }

  .prev, .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
  }
  
  .prev {
    left: 10px;
  }
  
  .next {
    right: 10px;
  }
  
  .dots {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: white;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .dot.active {
    background-color: gray;
  }

  .whatsappButton {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000; /* Para garantir que o botão fique acima de outros elementos */
  }
  
  .whatsappButton a {
    background-color: #25D366; /* Cor de fundo do botão */
    color: #ffffff; /* Cor do ícone */
    padding: 10px;
    border-radius: 50%; /* Para tornar o botão redondo */
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); /* Sombra para um efeito de elevação */
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .whatsappButton a:hover {
    background-color: #128C7E; /* Cor de fundo do botão ao passar o mouse */
    transform: scale(1.1); /* Efeito de escala ao passar o mouse */
  }
  
.blackfaixa {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 250px;
    padding: 1.8%;
    justify-content: center;
    background-color: black;
}

.bfport {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 35vh;
  padding: 1.8%;
  justify-content: center;
  background-color: black;
   text-align: right;
}

.btn-portfolio-fo {
    float: right;
    align-items: right;
    justify-content: right;
    text-align: right;
    margin-left: 81%;
    width: 250px;
    height: 100px;
}

.btn-empresa-fo {
    width: 250px;
    height: 100px;
}

.bfport h2.text-title-section {
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 70px;
    text-align: right;
    letter-spacing: 0.015em;
    color: #fff;
}

.bfempr {
    text-align: left;
}

.bfempr h2.text-title-section {
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 70px;
    text-align: left;
    letter-spacing: 0.015em;
    color: #fff;
}

.bfclie {
    text-align: right;
    justify-content: center;
}

.bfclie h2.text-title-section {
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 70px;
    text-align: left;
    letter-spacing: 0.015em;
    color: #fff;
}
.bfclie h3{
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 18px;
    text-align: left;
    letter-spacing: 0.001em;
    color: #fff;
}

.bfcont{
    text-align: right;
    justify-content: center;
}

.bfcont h2.text-title-section {
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 70px;
    text-align: right;
    letter-spacing: 0.015em;
    color: #fff;
}

.bfcont h3{
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 18px;
    text-align: right;
    letter-spacing: 0.001em;
    color: #fff;
}

.contato, .portfolio {
    display: flex;
    align-items: center;
    justify-content: center;
   
    flex-direction: column;
}

.contact-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5% 0; 
    width: 100%;
}

.contact-text{
    display: flex;
    width: 50%;
    height: 100%;
    margin-right: 2%;
}

.contact-text h3 {
    color: #1D1D1F;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 166.667% */
    letter-spacing: 0.36px;
}
.contact-form {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35%;
    height: 100%;
}

.orangeline {
    width: 92%;
    height: 2px;

    background-color: #FF8826;

    display: flex;

    align-items: center;
    justify-content: center;

    margin-bottom: 2%;
    margin-top: 2%;
}



.clients-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /*padding: 5% 0; */
    width: 100%;
    height: 300px;
}

.wrapperclients {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.clients-text {
    display: flex;
    width: 50%;
    height: 100%;
    /*margin-right: 2%;*/
    align-items: center;
    padding: 7% 0;
}

.clients-text h3 {
    width: 90%;
    color: #1D1D1F;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 166.667% */
    letter-spacing: 0.36px;
}
.clients-btn {
    display: flex;
    flex-direction: column;
    padding: 0px;
    align-items: center;
    justify-content: center;
    width: 35%;
    height: 100%;
}

.clients-btn a, .company-btns a{
    text-decoration: none;
    justify-content: center;
    display: flex;
    flex-direction: column;
}




.company-container {
    display: grid;
    grid-gap: 0px;
    grid-template-columns: 50% 50%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1380px;
    height: 500px;
    margin-top: 5%;
    margin-bottom: 5%;
}

.company-text {
    display: flex;
    width: 100%;
    height: 100%;
    /*margin-right: 2%;*/
    align-items: center;
    padding-top: 7%;
    padding-bottom: 7%;
    padding-left: 15%;
    padding-right: 0;
    float: left;
}

.company-text h3{
    width: 90%;
    color: #1D1D1F;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 166.667% */
    letter-spacing: 0.36px;
}

.company-img{
    display: flex;
    width: 100%;
    height: 100%;
    /*margin-right: 2%;*/
    align-items: center;
    justify-content: center;
    text-align: center;
    /*padding: 7% 0;*/  
}

.company-btns {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.company-img-div {
    width: 425px;
    height: 250px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border: .5px solid grey;
}




.portfolio-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /*padding: 5% 0; */
    width: 100%;
    height: 100%;
    margin-bottom: 5%;
}


@media (max-width: 480px) {
    .rotated-image{
      margin-top: 4vh;
    }
    .center-right {
        top: auto;
        bottom: 12.5vh;
        right: 40%;
        transform: translateX(50%);
      }

   /* .bfesp{
      height: 5vh;
    }*/
    .blackfaixa {
        height: 25vh;
        padding: 5%;
    }
    .company-btns {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      margin-top: 30vh;
  }
    .company-container {
        display: grid;
        grid-template-columns: 350px;
        grid-template-rows: 250px 250px 200px 120px;
        grid-row-gap: 75px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 1200px;
        
          
    }

    .company-img{
        display: flex;
        width: 100%;
        height: 100%;
        /*margin-right: 2%;*/
        align-items: center;
        justify-content: center;
        text-align: center;
        /*padding: 7% 0;*/  
        margin-top: 5vh;
    }

    .company-img-div {
        width: 100%;
    }
     
    .company-text {
        padding-left: 7%;
        padding-right: 7%;
        padding-top: 7%;
        padding-bottom: 0;

        margin-top: 10vh;

        height: 50vh;
    }

    .company-text-right h3 {
        width: 90%;
        color: #1D1D1F;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 166.667% */
        letter-spacing: 0.36px;
        text-align: right;
    }
    
    .bfclie h2.text-title-section {
        font-size: 25px;
        line-height: 25px;
    }
    .bfclie h3{
        font-size: 22px;
        letter-spacing: 0.001em;
        color: #fff;
    }
    
    .btn-clientes-fo, .btn-portfolio-fo {
        float: right;
        align-items: right;
        justify-content: right;
        text-align: right;
        margin-left: 25%;
        width: 250px;
        height: 100px;
    }

    .btn-empresa-fo {
        float: left;
        align-items: left;
        justify-content: left;
        margin-right: 50%;
        width: 250px;
        height: 100px;
    }

    .empr-text{
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    
    .empr-text h3 {
        text-align: center;
    }   

    .clients-container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 500px;
    }
    
    .clients-text {
        width: 100%;
        height: 280px;
        align-items: center;

        padding: 0 30px 0 30px;
    }
    
    .clients-text h3 {
        width: 100%;
        text-align: center;
    }


    .clients-btn {
        width: 100%;
        height: 120px;
    }
    

    .contact-container {
        flex-direction: column;
        height: 820px;
    }

    .contact-text{
        width: 100%;
        height: 340px;
        align-items: center;
        justify-content: center;

        padding: 0 30px 0 30px;
    }
    
    .contact-text h3 {
        text-align: center;
    }   

    .bfcont h2.text-title-section {
        font-size: 25px;
        line-height: 28px;
        text-align: right;
    }
    
    .bfcont h3{
        font-size: 14px;
    }

    .orangeline {
        width: 82%;
        height: 2px;
    
        /*background-color: #FF8826;*/
    
        display: flex;
    
        align-items: center;
        justify-content: center;
    
        margin-bottom: 2%;
        margin-top: 10%;
    }

    .portfolio-container {
      display: flex;
      flex-direction: column;
      
  }
  /*---*/
  .divteste{
 
   
    height: 650px;
    
  }
}