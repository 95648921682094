@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;1,200;1,300;1,400;1,600&display=swap');



.btn-default {
    background: #F58320;
    border-radius: 6px;

    width: 192px;
    height: 32px;

    border: none;
    color: #fff;
    text-align: center;
    text-decoration: none;
    font-size: 17px;
    line-height: 21px;
    cursor: pointer;
    font-family: 'Nunito', Arial, Helvetica, sans-serif;
    letter-spacing: 0.05em; 
    font-weight: 500;

    padding: 0px;
    transition: background-color .8s ease;
}

.btn-default:hover {
    background-color: #000;
    color: #F58320;
}

@media (max-width: 480px) {
    .btn-default {
        width: 22px;
        height: 52px;

        font-size: 17px;
        background: red;
        line-height: 21px;
    }
    
}