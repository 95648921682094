@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,800;1,200;1,400;1,700&display=swap');

.developed {
    width: 120px;
    height: 105px;

    position: absolute;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    left: 90%;
    margin-bottom: 31vh;

}
.developed h3 {
    text-align: center;
    color: #FFFFFF;
    font-size: 12px;
    margin-bottom: 10px;
    font-weight: 200;
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
}
.developed img {
    width: 55%;
    margin-bottom: 10px;
    text-align: center;

}

.linkvw {
    text-align: center;
}

.globalfooter {
    width: 100%;
    height: 50vh;

    background-color: #000;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

   
    
}

.iconsspace {
    display: grid;
    width: 100px;
    height: 30px;
    margin-top: 4vh;



    grid-template-columns: 1fr 1fr;
}

.icon {
    width: 50px;
    height: 25px;

    color: #FFFFFF;
}

.legend {
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.1em;

    color: #FFFFFF;

    margin-top: 7vh;

    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
}

.arealogo {
    width: 100%;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.logofooter {
    width: 10%;
    margin: auto;
  display: block;
}

.officialinfo {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.1em;

    color: #FFFFFF;

    

    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
}
.auxfooter{
    width: 100%;
    height: 100%;
}

@media (max-width: 480px){
    .logofooter {
        width: 42%;
    }

    .iconsspace {
       margin-top: 7vh;
    }
    .developed{
        width: 105px;
        left: 70%;
        margin-bottom: 77.3%;
  }

  .legend {
    font-size: 16px;
  
  }

  .globalfooter {
    height: 62vh;
  }
  .auxfooter{
    margin-top: 50px;
   
  }
  .arealogo {
    width: 100%;
    height: 150px;
}
}

