@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;1,200;1,300;1,400;1,600&display=swap');

a {
    text-decoration: none;
}

.globalsocialmedia {
    width: 1000px;
    height: 50px;

    display: flex;
    flex-direction: column;

 
    display: flex;
    align-items: center;
    justify-content: center;
}

.icons-space {
    display: grid;

    grid-template-columns: 35px 195px 35px 175px 35px 200px;
    grid-template-rows: 50px;

    grid-column-gap: 10px;
}

.content-text {
    padding: 5px;
   
}

.icon-social {
    width: 50px;
    height: 25px;

    color: #FF8826;
}

.content-text h2 {
    text-decoration: none;
    color: black;
    font-family: 'Titillium Web', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
   

    letter-spacing: 0.05em;
    text-align: left;  
}

@media (min-width: 2000px){
    .globalsocialmedia {
        width: 600px;
        height: 300px;
    }

    .icons-space {
        grid-template-columns: 100px 350px;
        grid-template-rows: 70px 70px 70px;
    
        grid-row-gap: 40px;
    }

    .icon-social {
        width: 100px;
        height: 50px;
    }
    
    .content-text h2 {
        font-size: 28px;
        line-height: 30px;
       
        letter-spacing: 0.1em; 
    }
}

@media (max-width: 480px) {
    .globalsocialmedia {
        width: 375px;
        height: 200px;
    
        display: flex;
        flex-direction: column;
    
     
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .icons-space {
        display: grid;
    
        grid-template-rows: 50px 50px 50px;
        grid-template-columns: 50px 180px;
    
        grid-column-gap: 10px;
    }
}





