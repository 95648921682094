@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,800;1,200;1,400;1,700&display=swap');


* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

html, body, #root {
  min-height: 100%;
} 

body {
  background-color: #fff;
  background-size: cover;
  -webkit-font-smoothing: antialised !important;
}

.lp-global {
    position: relative;
    /*display: flex;
    flex-direction: column;
  
    align-items: center;*/
}

.lp-content { 
    width: 100%;
    height: 100%;
   
    display: flex;
    flex-direction: row;
   
    /*align-items: center;*/
    text-align: center; 
    padding-left: 5vw;
    padding-right: 5vw;
}

.lp-content-two{
  width: 100%;
    height: 100%;
   
    display: flex;
    flex-direction: row;
    justify-content: center;
   
    /*align-items: center;*/
    text-align: center; 
    padding-left: 5vw;
    padding-right: 5vw;
}

.black-faixa h2.text-promo {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 50px;
    text-align: center;
    letter-spacing: 0.015em;
    color: white;
    justify-content: center;
}

.sldgallaeryarea {
  width: 100%;
  height: 100%;
  background-color: green;
  display: flex;

  justify-content: center;
}

.text-areaone h2.text-promo {
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 70px;
    text-align: left;
    letter-spacing: 0.015em;
    color: #1D1D1F;
}

.text-areaone {
    width: 50%;
    height: 270px;
    padding: 5%;
    display: flex;
    float: left;
}

.logo-areaone {
    width: 50%;
    height: 270px;
    padding: 5%;
    display: flex;
    float: right;
    flex-direction: column;
    align-items: center;
    text-align: center; 
}

.logomainlp {
  width: 75%;
  margin-top: 3vh;
}

.black-faixa {
  width: 100%;
  height: 200px;
  padding: 1.8%;
  align-items: center;
  justify-content: center;
  background-color: black;

}

.forms-areaone {
  width: 60%;
  height: 100%;
  padding: 5%;
  display: flex;
  float: right;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.slider-areaone {
  width: 100%;
  height: 570px;
  display: flex;

  align-items: center;
  text-align: center;

  background-color: red;
}

@media (max-width: 480px){
  .lp-content { 

    flex-direction: column;
}

  .text-areaone {
    width: 100%;
    height: 320px;
  }

  .text-areaone h2.text-promo {
    text-align: center;
   
}
.black-faixa {
  height: 255px;
}
.black-faixa h2.text-promo {
    font-size: 25px;
    line-height: 40px;
   
}

  .logo-areaone {
    width: 100%; 
}

  .forms-areaone {
    width: 100%;
  }

  .slider-areaone {
    width: 100%;
  }
}