.wrapper {
    display: grid;

    /*grid-template-rows: 2;*/
    grid-gap: 60px;

   grid-template-columns: 300px 300px 300px;

    align-items: center;
    justify-content: center;

    margin-top: 35vh;
}

@media (max-width: 991px) {
    .wrapper {
        display: grid;

        grid-template-rows: auto;
        grid-gap: 60px;

        align-items: center;
        justify-content: center;

        margin-top: 26vh;
    }
}

@media (max-width: 480px) {
    .wrapper {
        display: grid;

        /*grid-template-rows: 2;*/
        grid-gap: 60px;

        grid-template-columns: 100%;

        align-items: center;
        justify-content: center;

        margin-top: 10vh;
    }
}
.img-client1 {
    width: 250px;
    height: 70px; 
}

.img-client2 {
    width: 250px;
    height: 70px;
}

.img-client3 {
    width: 250px;
    height: 70px;
}

.img-client4 {
    width: 250px;
    height: 70px;
}

.img-client5 {
    width: 250px;
    height: 70px;
}