.logobar {
    width: 25%;
}

@media (max-width: 480px) {
    .logobar {
        width: 100%; /* Alterando a largura do logotipo para 100% em telas menores que 480px */
        margin-left: 0; /* Removendo a margem para centralizar */
    }
}
