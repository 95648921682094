

.slider{
    width: 100%;
    height: 650px;
   /* border-radius: 10px;*/
    overflow: hidden;
}

.slides{
    width: 500%;
    height: 650px;
    display: flex;
}

.slides input {
    display: none;
}

.slide {
    width: 20%;
    transition: 2s;
}

.slide img {
    width: 100%;
    height: 650px;
}

.navigation-manual{
    position: absolute;
    width: 100%;
    margin-top: -40px;
    display: flex;
    justify-content: center;
}

.manual-btn{
   /* border: 2px solid green;*/
    background-color: rgba(245, 131, 32, 1);
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
    transition: .3s;
    width: 14px;
    height: 14px;
}

.manual-btn:not(:last-child){
    margin-right: 40px;
}

.manual-btn:hover{
    background-color: rgb(231, 117, 16);
}

#radio1:checked ~ .first{
    margin-left: 0;
}

#radio2:checked ~ .first{
    margin-left: -20%;
}

#radio3:checked ~ .first{
    margin-left: -40%;
}
#radio4:checked ~ .first{
    margin-left: -60%;
}



.navigation-auto {
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 610px;
}

.navigation-auto div{
    /*border: 2px solid greenyellow;
    background-color: #F58320;*/
    padding: 5px;
    border-radius: 10px;
    transition: 1s;
    width: 14px;
    height: 14px;
}

.navigation-auto div:not(:last-child){
    margin-right: 40px;   
}

#radio1:checked ~ .navigation-auto .auto-btn1{
    border: 1px solid black;
    background-color: rgba(245, 131, 32, 1);
    z-index: 999;
}

#radio2:checked ~ .navigation-auto .auto-btn2{
    border: 1px solid black;
    background-color: rgba(245, 131, 32, 1);
    z-index: 999;
}

#radio3:checked ~ .navigation-auto .auto-btn3{
    border: 1px solid black;
    background-color: rgba(245, 131, 32, 1);
    z-index: 999;
}
#radio4:checked ~ .navigation-auto .auto-btn4{
    border: 1px solid black;
    background-color: rgba(245, 131, 32, 1);
    z-index: 999;
}

@media (max-width: 480px){
    .slider {
        height: 350px;
    }

    .slides {
        height: 350px;
        width: 100%;
    }

    .slide img {
        height: 350px;
    }

    .navigation-auto {
        margin-top: 310px;
    }
  }